:root {
    --primary-line-height: 1.6;
}

.not-found {
    margin-top: 72px;
    height: calc(100vh - 72px - 158px);
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.image-container,
.text-container {
    flex: 1 1 0;
    padding: 20px;
    max-width: 100%;
}

.image-container img {
    width: 100%;
    height: auto;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text-container > * {
    margin-bottom: 2vh;
    font-family: var(--nunito);
    line-height: var(--primary-line-height);
}

.text-container .text-1 {
    color: var(--color-highlight);
    font-size: clamp(20px, 4vw, 30px);
    font-weight: 700;
}

.text-container .text-2 {
    color: black;
    font-size: clamp(30px, 5vw, 50px);
    font-weight: 700;
}

.text-container .text-3 {
    color: darkgrey;
    font-size: clamp(14px, 2vw, 20px);
}

@media screen and (max-width: 1200px) {
    .text-container > * {
        margin-bottom: 1.5vh;
    }
}

.btn-404 a {
    padding: 10px;
    color: var(--color-white);
    background: var(--color-highlight);
    font-family: var(--nunito);
    text-decoration: none;
    border-radius: 8px;
    font-weight: 400;
    line-height: 26px;
}

@media screen and (max-width: 960px) {
    .not-found {
        display: block;
        padding: 20px 0;
    }

    .not-found .image-container,
    .not-found .text-container {
        width: 100%;
    }

    .text-container {
        align-items: center;
    }
}