.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: auto;
    width: 100%;
    background-color:#323743FF;
    padding: 20px;
    position: relative;
}

.form-title {
    margin: 10px;
    font-family: var(--montserrat);
    font-size: 22px;
    color: white;
    text-align: left;
    align-self: flex-start;
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 10px;
}
.flex > * {
    flex: 1;
}
.form label {
    position: relative;
}

.form label .input {
    width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid beige;
    border-radius: 5px;
}

.form label .input + span {
    position: absolute;
    left: 10px;
    top: 15px;
    color: #323743FF;
    font-size: 0.9em;
    cursor: text;
    transition: 0.3s ease;
}

.form label .input:placeholder-shown + span {
    font-family: var(--quicksand);
    top: 15px;
    font-size: 18px;
}
.input:placeholder-shown,
.input01:placeholder-shown{
    font-family: var(--quicksand);
    font-size: 18px;
}
.form label .input:focus + span,.form label .input:valid + span {
    top: 30px;
    font-size: 0.7em;
    font-weight: 600;
}

.form label .input:valid + span {
    color: green;
}

.input01 {
    width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid  #323743FF;
    border-radius: 5px;
}

.form label .input01 + span {
    font-family: var(--quicksand);
    position: absolute;
    left: 10px;
    top: 50px;
    color:  #323743FF;
    font-size: 18px;
    cursor: text;
    transition: 0.3s ease;
}

.form label .input01:placeholder-shown + span {
    font-family: var(--quicksand);
    top: 40px;
    font-size: 18px;
}

.form label .input01:focus + span,.form label .input01:valid + span {
    font-family: var(--quicksand);
    top: 50px;
    font-size: 0.7em;
    font-weight: 600;
}

.form label .input01:valid + span {
    color: green;
}

.fancy {
    border-radius: 10px;
    margin: 20px;
    width: 40%;
    padding: 15px;
    background-color: transparent;
    border: 2px solid white;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 390;
    letter-spacing: 2px;
    outline: none;
    overflow: visible;
    text-align: center;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.fancy .text {
    font-family: var(--quicksand);
    font-size: 18px;
    line-height: 25px;
    transition: all 0.3s ease-in-out;
    text-transform: lowercase;
    text-decoration: none;
    color: white;
}

.fancy:hover {
    color: white;
    background: cadetblue;
}

.fancy:hover::before {
    width: 1.5rem;
    background: white;
}

.fancy:hover .text {
    color: white;
}