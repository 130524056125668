.footer {
    user-select: none;
    padding: 20px 20px 0;
    position: absolute;
    width: 100%;
    height: auto;
    background: #323743FF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.top-container {
    width:80%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-around;
}
.outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.column {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.bottom-container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    flex-direction: column;
}

.footer-line {
    width: 100%;
    height: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffff80;
}

.footer-text {
    margin: 20px;
    bottom: 0;
    font-family: var(--quicksand);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFFFF;
}

.footer-title {
    letter-spacing: 1px;
    font-family: var(--montserrat);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #FFFFFFFF;
}

.footer-link{
    margin: 0 10px;
    letter-spacing: 1px;
    text-decoration: none;
    color: white;
    font-size: 16px;
    line-height: 30px;
    font-family: var(--quicksand);
}

@media (max-width: 500px) {
    .top-container {
        width: 100%;
    }
    .footer-link {
        letter-spacing: 0;
        font-size: 12px;
    }
}