.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icon {
    width: 24px;
    height: 24px;
    margin: 0 10px;
    fill: #fff;
}


.facebook:hover {
    fill: #316FF6;
}

.instagram:hover {
    fill: deeppink;
}

.linkedin:hover {
    fill: #0077B5;
}
