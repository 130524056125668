.video {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    min-width: 400px;
    min-height: 200px;
}

.video-player {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 1400px) {
    .video {
        min-width: 700px;
        min-height: 400px;
    }
}

@media (max-width: 800px) {
    .video {
        min-width: 400px;
        min-height: 300px;
    }
}

@media (max-width: 600px) {
    .video {
        min-width: 400px;
        min-height: 250px;
    }
}

@media (max-width: 500px) {
    .video {
        min-width: 320px;
        min-height: 180px;
    }
}

@media (max-width: 400px) {
    .video {
        min-width: 280px;
        min-height: 140px;
    }
}

@media (max-width: 360px) {
    .video {
        min-width: 250px;
        min-height: 100px;
    }
}
