@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');

:root {
    --nunito: "Nunito Sans", sans-serif;
    --quicksand: "Quicksand", sans-serif;
    --montserrat: "Montserrat", sans-serif;
    --poppins: "Poppins", sans-serif;
    --font-size-primary: 25px;
    --font-size-secondary: 18px;
    --color-white: white;
    --color-tertiary: #300A0A;
    --secondary-grey: #565d6d;
    --color-highlight: #f16436;
}


.stat-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hero {
    text-align: left;
    margin-left: 5%;
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.text {
    padding: 20px;
    font-family: var(--nunito);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #171a1fff;
    width: 100%;
}

.receipt-container {
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}
@media (max-width: 1200px) {
    .hero {
        .text {
            font-size: 18px;
        }

        flex-direction: column;
        align-items: center;
    }
}