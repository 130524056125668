.services {
    margin-top: 104px;
    z-index: 0;
}

.services-hero-title {
    font-family: var(--nunito);
    font-size: 30px;
    line-height: 35px;
    color: var(--color-highlight);
}

.services-hero-container {
    margin: 20px;
    width: 100%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


@media screen and (max-width: 1200px) {
    .services-hero-container {
        max-width: 100%;
    }
}