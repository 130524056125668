.value {
    width: 100%;
    max-width: 320px;
    max-height: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    color: white;
    background-color: transparent;
}

.value-icon {
    margin: 5px;
    align-self: flex-start;
    width: 40px;
    height: 40px;
    fill: var(--color-white);
}

.value-title {
    font-family: var(--nunito);
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
    color: var(--color-white);
}

.value-text {
    font-size: 16px;
    color: var(--color-white);
}

.white-background {
    background-color: var(--color-white);
}

.icon-red {
    fill: var(--color-highlight);
}

.red {
    color: var(--color-highlight);
}

@media (max-width: 700px) {
    .value {
        max-width: 280px;
        max-height: 260px;
        padding: 10px;
    }

    .value-icon {
        width: 30px;
        height: 30px;
    }

    .value-title {
        font-size: 20px;
        line-height: 28px;
    }

    .value-text {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 600px) {
    .value {
        max-width: 240px;
        max-height: 220px;
    }

    .value-icon {
        width: 25px;
        height: 25px;
    }

    .value-title {
        font-size: 24px;
        line-height: 26px;
    }

    .value-text {
        font-size: 14px;
        line-height: 18px;
    }
}