.language-select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #FF5733;
    border-radius: 5px;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    color: #FF5733;
}

.language-select:focus {
    border-color: #FF5733;
    box-shadow: 0 0 0 0.2rem rgba(255, 87, 51, 0.25);
}

@media (max-width: 960px) {
    .language-select {
        font-size: 14px;
        padding: 4px;
    }
}
