:root {
    --font-size-default: 16px;
    --font-size-small: 14px;
}

.navigation-path {
    user-select: none;
    position: absolute;
    top: 76px;
    width: 100%;
    padding-left: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navigation-path-text {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
}

.indicator-red {
    color: var(--color-highlight);
}
.indicator-grey {
    color: var(--secondary-grey);;
}

.background {
    background: #F3F4F6FF;
}

.slash {
    height: 25px;
    width: auto;
}

@media (max-width: 600px) {
    .navigation-path-text {
        font-size: 14px;
    }
}