.product {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 104px;
    width: 100%;
    height: auto;
    background: #F3F4F6FF;
}


.product-container {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}


.photo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.photo-inner-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 900px) {
    .product-container {
        grid-template-columns: 1fr;
    }

    .photo-inner-container {
        flex-direction: column;
    }
}