.products {
    z-index: 0;
}

.products-hero {
    margin-top: 104px;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: auto;
    background: var(--color-white);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.products-hero-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.products-hero-text {
    margin: 20px;
    font-family: var(--nunito);
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    color: #323743FF;
    width: 100%;
    max-width: 400px;
}

.products-hero-image {
    user-select: none;
    margin: 20px;
    width: 100%;
    max-width: 600px;
    height: auto;
}

.product-section {
    width: 80%;
    margin: 50px 10%;
    height: auto;
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.product-section-item {
    margin: 20px;
    width: 100%;
    height: 100%;
    max-width: 570px;
    max-height: 290px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: normal;
}

.first-color {
    max-width: 490px;
    background: #FEF2F1FF;
}

.second-color {
    max-width: 490px;
    background: #FEF8F0FF;
}

.third-color {
    height: auto;
    width: 80%;
    max-width: 100%;
    background: #FAC7C3FF;
}

.product-section-text {
    margin: 20px;
    text-align: left;
    width: 100%;
    font-family: var(--nunito);
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #171A1FFF;
}


@media (max-width: 960px) {
    .product-section-text,
    .products-hero-text {
        font-size: 18px;
    }

    .products-hero {
        flex-direction: column;
    }

    .product-section {
        flex-direction: column;
    }
    .third-color {
        max-width: 490px;
        width: 100%;
    }
}

@media (max-width: 630px) {
    .product-section-text,
    .products-hero-text {
        font-size: 16px;
    }
}

@media (max-width: 330px) {
    .product-partner-text,
    .product-section-text,
    .products-hero-text {
        font-size: 14px;
    }
}