.home {
    z-index: 0;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.hero-section {
    background: var(--color-highlight);
    height: calc(100vh - 72px);
    width: 100%;
}

.hero-section .hero-image {
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/background-1.png') center/cover no-repeat;
}

.main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeInUp 1s ease forwards;
}

.main-content .site-title {
    font-size: 40px;
    font-family: var(--montserrat);
    font-weight: bold;
    letter-spacing: 8px;
    line-height: 48px;
}

.main-content h3 {
    font-size: 25px;
    margin-bottom: 15px;
    font-weight: lighter;
    font-family: var(--nunito);
}

.main-content p {
    font-size: 25px;
    margin-top: 15px;
    font-weight: lighter;
    font-family: var(--nunito);
}

.main-content p,
.main-content h3,
.main-content .site-title {
    color: var(--color-white);

}

.btn {
    user-select: none;
    margin-top: 25px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--montserrat);
    font-size: 18px;
    line-height: 28px;
    color: var(--secondary-grey);
    text-decoration: none;
    border-radius: 26px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.outlined-btn {
    width: 282px;
    background: transparent;
    border: 2px solid var(--color-white);
    color: var(--color-white);
}

.filled-btn {
    width: 282px;
    background: var(--color-white);
    color: var(--secondary-grey);
}

.outlined-btn:hover {
    background: var(--color-white);
    color: var(--secondary-grey);
}

.filled-btn:hover {
    background: darkslategrey;
    color: var(--color-white);
}

.button-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.section-1,
.section {
    width: 100%;
    padding: 50px 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.section-1 {
    flex-direction: column;
}

.section-1 .section-container-3-reverse,
.section-1 .section-container-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.section-1 .section-container-3-reverse {
    flex-direction: row-reverse;
}

.section-container-3-reverse img,
.section-container-3 img {
    padding: 10px;
    width: auto;
    height: 178px;
    border-radius: 8px;
}

.section .section-container-1 {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 50%;
}

.section-container-1 .item-2 {
    font-family: var(--nunito);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #300A0AFF;
    text-align: center;
}

.section-container-1 .item-1 {
    padding-left: 50px;
    font-family: var(--montserrat);
    font-size: 30px;
    font-weight: 700;
    line-height: 46px;
    color: #EF4637FF;
    text-align: center;
}

.section .section-container-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 50%;
}

.section-container-2 .section-btn {
    width: 282px;
    background: var(--color-highlight);
    color: var(--color-white);
}

.section-image {
    width: 100%;
    height: auto;
    background: url('../../assets/background-2.png') center/cover no-repeat;
}

@media (max-width: 1160px) {
    .button-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .filled-btn {
        margin-top: 10px;
    }

    .section {
        flex-direction: column;
    }

    .section .section-container-1, .section .section-container-2 {
        width: 100%;
    }

    .section-container-1 .item-1 {
        padding-top: 5px;
        padding-left: 0;
        text-align: center;
    }

    .section-1 .section-container-3-reverse,
    .section-1 .section-container-3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-container-3-reverse img,
    .section-container-3 img {
        height: 120px;
    }
}

@media (max-width: 1000px) {
    .main-content .site-title {
        font-size: 30px;
        line-height: 40px;
    }

    .main-content h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .main-content p {
        font-size: 20px;
        margin-top: 10px;
    }
}

/* Styles for screens wider than 700px */
@media (max-width: 700px) {
    .main-content .site-title {
        font-size: 25px;
        line-height: 32px;
    }

    .main-content h3 {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .main-content p {
        font-size: 18px;
        margin-top: 8px;
    }
}

/*@media (max-width: 450px) {*/
/*    .main-content .site-title {*/
/*        font-size: 20px;*/
/*        line-height: 26px;*/
/*    }*/

/*    .main-content h3 {*/
/*        font-size: 16px;*/
/*        margin-bottom: 6px;*/
/*    }*/

/*    .main-content p {*/
/*        font-size: 16px;*/
/*        margin-top: 6px;*/
/*    }*/
/*}*/