.about {
    margin-top: 104px;
    z-index: 0;
}
.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.about-hero-item {
    padding: 20px;
}

.about-video {
    height: auto;
    width: 80%;
    box-sizing: border-box;
}

@media (max-width: 1400px) {
    .text {
        font-size: 16px;
        padding: 0;
    }
}

@media (max-width: 1000px) {
    .text {
        font-size: 14px;
    }
}
