.why-us {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: var(--color-highlight);
}

.why-us-title {
    overflow: visible;
    margin: 20px;
    font-family: var(--nunito);
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
    color: var(--color-white);
}

.values-container {
    margin: 0 40px 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1000px) {
    .values-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .values-container .value:nth-child(3) {
        grid-column: 2;
    }

    .values-container .value:nth-child(4) {
        grid-row: 2;
        grid-column: 1;
    }
}

@media screen and (max-width: 800px) {
    .why-us-title {
        font-size: 30px;
    }
}