.product-detail {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.product-detail-container {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.product-detail-title {
    margin: 10px;
    font-family: var(--nunito);
    color: var(--color-highlight);
    font-size: 26px;
    font-weight: 700;
    line-height: 35px;
}

.product-detail-text {
    text-align: left;
    margin: 10px;
    font-family: var(--nunito);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #323743FF;
}

.product-detail-icon {
    width: 25px;
    height: 25px;
    fill: var(--color-highlight);
}