.receipt-info {
    margin: 20px auto;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.receipt-text {
    text-align: left;
    font-family: var(--nunito);
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #171a1fff;
}

.receipt-title {
    margin: 25px;
    text-align: left;
    letter-spacing: 1px;
    font-family: var(--montserrat);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #171a1fff;
}