.stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stat-title {
    padding: 30px;
    font-family: var(--montserrat);
    font-size: 60px;
    font-weight: 400;
    line-height: 64px;
    color: #512311FF;
}

.stat-subtitle {
    top: 211px;
    left: 835px;
    font-family: var(--nunito);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: var(--color-tertiary);
}

.stat-line {
    height: 64px;
    border-width: 1px;
    border-color: #BDC1CAFF;
    border-style: solid;
}


@media (max-width: 1160px) {
    .stat-title {
        font-size: 45px;
        line-height: 49px;
    }

    .stat-subtitle {
        font-size: 12px;
        line-height: 14px;
    }

    .stat-line {
        line-height: 35px;
    }
}


@media (max-width: 400px) {
    .stat-title {
        padding: 15px;
        font-size: 40px;
        line-height: 45px;
    }

    .stat-subtitle {
        font-size: 10px;
        line-height: 12px;
    }

    .stat-line {
        line-height: 30px;
    }
}