.navbar {
  user-select: none;
  z-index: 1000;
  width: 100%;
  font-size: 18px;
  height: 72px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  background-color: white;
  padding: 15px 2%;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
}

img {
  height: 55px;
  width: auto;
  cursor: pointer;
}

.menu-icon {
  display: none;
}

.dropdown-icon {
  display: none;
}

.nav-menu {
  z-index: 1;
  flex: 1;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu {
  display: flex;
}

.nav-item {
  display: block;
  height: 57px;
  border-bottom: 2px solid transparent;
}

.hover-effect:hover {
  border-bottom: 3px solid var(--color-highlight);
}

.nav-links {
  padding: 0 15px;
  font-family: var(--poppins);
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 2px;
  color: #565d6d;
  text-decoration: none;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.activated {
  color: var(--color-highlight);
}

.language-selector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown .nav-links {
  text-align: center;
  display: flex;
  justify-content: center;
}

.dropdown-content {
  border-radius: 0 0 15px 15px;
  display: none;
  position: absolute;
  background-color: white;
  z-index: 1;
  box-shadow: rgba(17, 17, 26, 0.05) 0 1px 0, rgba(17, 17, 26, 0.1) 0 0 8px;
  width: 127px;
}

.dropdown-content.prescription {
  margin-top: 0;
}

.dropdown-content a {
  font-size: 16px;
  font-family: var(--poppins);
  color: var(--secondary-grey);
  text-decoration: none;
  display: block;
  padding: 12px 16px;
}

.dropdown-content a:hover {
  border-radius: 15px;
  background-color: var(--color-highlight);
  color: white;
}

.dropdown-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 960px) {
  .dropdown:hover .dropdown-content {
    display: block;
  }

}

@media screen and (max-width: 960px) {
  .disable {
    display: none;
  }

  .dropdown-content {
    position: relative !important;
  }

  .dropdown-content.open {
    width: 100%;
    display: block;
  }

  .dropdown {
    position: relative;
    height: auto;
    padding: 7px 0;
  }

  .dropdown.active .dropdown-content {
    display: block;
  }

  .navbar {
    padding: 15px 5%;
    position: fixed;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--color-highlight);
    z-index: 2;
  }

  .dropdown-icon {
    display: flex;
    align-self: center;
    margin-left: 10px;
    font-size: 18px;
    cursor: pointer;
    color: var(--color-highlight);
  }

  .language-selector {
    margin-right: 10%;
    margin-left: auto;
    z-index: 2;
  }

  .nav-menu {
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 72px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
  }

  .nav-links {
    text-align: center;
  }

  .hover-effect:hover {
    border-bottom: 2px solid var(--color-highlight);
  }
}
