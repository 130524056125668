.receipt-item {
    color: #fb3d38;
    margin: 5px;
    border: 1px solid lightcoral;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 60px;
    border-radius: 25px;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
    background-color: #ffffff;
}
.item-selected,
.receipt-item:hover {
    background-color: #F0808030;
    border-color: #F08080;
}

.receipt-item-text {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    font-family: var(--quicksand);
    text-align: center;
    z-index: 1;
    position: relative;
}

@media (max-width: 530px) {
    .receipt-item {
        width: 140px;
        height: 42px;
    }

    .receipt-item-text {
        font-size: 14px;
    }

    .receipt-item-image {
        width: 25px;
        height: 25px;
    }
}
