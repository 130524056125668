.App {
    text-align: center;
    width: 100%;
    margin-top: 72px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

* {
    -webkit-tap-highlight-color: transparent;
}