
.contacts {
    margin-top: 72px;
    height: auto;
    z-index: 0;
    width: 100%;
    background: url('../../assets/contact/contact-background.png') center/cover no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.location-items {
    width: auto;
    display: grid;
    grid-gap: 15px;
    max-width: 100%;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
}

@media (max-width: 1160px) {
    .location-items {
        display: flex;
        flex-direction: column;
    }
}