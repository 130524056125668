.title-container {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.main-title {
    font-size: 30px;
    font-family: var(--montserrat);
    font-weight: 700;
    color: #323743FF;
}

.subtitle-item {
    margin: 10px;
    width: 250px;
}

.subtitle-item path {
    fill: var(--color-highlight);
}

@media (max-width: 800px) {
    .main-title {
        font-size: 20px;
    }

    .subtitle-item {
        width: 200px;
    }
}