.address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    background: rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
    transition: background 0.3s ease-in-out;
}

.address:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
    z-index: -1;
}

.address-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.address-text {
    font-family: var(--nunito);
    font-size: 30px;
    line-height: 35px;
    color: var(--color-highlight);
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
}

.address-icon {
    width: 15px;
    height: 15px;
    fill: var(--color-highlight);
}

.address-details {
    font-family: var(--montserrat);
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.address.active .address-details {
    opacity: 1;
}
.address-details-title {
    font-weight: bold;
    align-self: center;
    font-size: 22px;
    margin-bottom: 20px;
    color: darkslategrey;
}
.address-details-address {
    color: darkslategrey;
    word-wrap: break-word;
    text-align: start;
    margin: 5px;
}
.address-details-link {
    margin: 5px;
    color: var(--color-highlight);
    text-decoration: none;
}

.hover:hover {
    background: linear-gradient(135deg, rgba(255, 183, 77, 0.5), rgba(255, 223, 186, 0.7));
    transition: background 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
    .address-icon {
        fill: white;
    }
    .address-text {
        color: white;
    }
}

@media (max-width: 600px) {
    .address-details {
        font-size: 16px;
    }
    .address-text {
        font-size: 25px;
        line-height: 30px;
    }
    .address-details-title {
        font-size: 18px;
    }
}