.map {
    position: relative;
    width: 100%;
    height: 80vh;
}

.yandex{
    width: 100%;
    height: 100%;
}

.map-component {
    left: 15%;
    top: 10%;
    z-index: 1;
    width: 400px;
    height: 80%;
    padding: 20px;
    position: absolute;
}

.address-container::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

.address-container {
    gap: 5px;
    width: 100%;
    height: 80%;
    overflow-y: auto;
}

@media (max-width: 600px) {
    .map-component {
        left: 5%;
        width: 350px;
    }
}