.partner {
    user-select: none;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin: 15px 5%;
}

.partner-container {
    height: auto;
    gap: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
}

.partner-text {
    padding: 15px;
    text-align: center;
    font-size: 30px;
    color: #EF4637FF;
    font-family: var(--montserrat);
    font-weight: bold;
}

.partner-logo {
    filter: grayscale(20%);
    mix-blend-mode: multiply;
    height: 100%;
    max-height: 50px;
    width: auto;
    display: block;
}

@media (max-width: 1010px) {
    .partner-logo {
        max-height: 40px;
    }
}

@media (max-width: 820px) {
    .partner-logo {
        max-height: 30px;
    }
}
@media (max-width: 620px) {
    .partner-logo {
        max-height: 20px;
    }
}